// If the process object doesn't exist, we're likely using webpack dev server which does not inject configs in the HTML.

// In production we expect the server to initialize the process object before loading any scripts, see scripts/server.mjs for more info.
if (!window.process) {
    // eslint-disable-next-line
    (window as any).process = {
        env: {
            NODE_ENV: bundled.env.NODE_ENV,
            PUBLIC_URL: bundled.env.PUBLIC_URL,
            LOGGER_PREFIX: bundled.env.LOGGER_PREFIX,
            LOGGER_SOURCE: bundled.env.LOGGER_SOURCE,
            LOGGER_WSS_ENDPOINT: bundled.env.LOGGER_WSS_ENDPOINT,
            TBID_LOGIN_URL: bundled.env.TBID_LOGIN_URL,
            LOGOUT_URL: bundled.env.LOGOUT_URL,
            TBID_DOMAIN: bundled.env.TBID_DOMAIN,
            KI_PRIVATE_API_URL: bundled.env.KI_PRIVATE_API_URL,
            TBID_SIGNUP_URL: bundled.env.TBID_SIGNUP_URL,
            COVEO_TOKEN: bundled.env.COVEO_TOKEN,
            COVEO_SOURCE_LOCATION: bundled.env.COVEO_SOURCE_LOCATION,
            COVEO_PIPELINE: bundled.env.COVEO_PIPELINE,
            SHOW_BANNER: bundled.env.SHOW_BANNER,
            TEXT_BANNER: bundled.env.TEXT_BANNER,
        }
    };
}

// Use dynamic import here because regular imports are resolved before any code is executed.
// We need to setup the environment variables before importing and evaluating any modules.
import('./main');
